.heading {
    background-color: #000;
    color: #b59852;
    padding-left: 8px !important;
    padding: 6px;
}

.lbl {
    font-weight: bold;
}

.slink {
    cursor: pointer;
}

.tbl-bk {
    background: #b59852;
}
.request-mod{
    display: flex;
    justify-content: space-around;
}
.request-mod hr{

}
.txt-center-row{
    margin: 20px 0;
    padding: 13px ;
    text-align: center;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    justify-content: center;
    flex-direction: column;
}
.txt-center-row p, .txt-center-row label{
    margin: 0;
}
.label-txt{
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 7px;
}
.request-mod h5{
    margin-bottom: 25px;
}
.w50{
    width: 50%;
}
.group-field{
    position: relative;
    display: flex;
    justify-content: space-between;
}
.group-field2{
    padding: 0 15px;
    margin-bottom: 15px;
    justify-content: start;
}
.group-field2 .form-control{
    margin: 0;
}
.group-field2 label{
    width: 25%;
}

.mand-field{
    position: absolute;
    width: 70%;
    bottom: -30px;
    right: 0;
}
.vl2{
    height: 470px;
}
.cl-det label{
    float: left;
    width: 40%;
}
.b-btns{
    margin:0 15px 15px;
}