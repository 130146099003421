.spinner-container {
    position: relative;
    min-height: 100%;
}

.spinner-full-width {
    position: fixed !important;
    min-height: 100%;
}

.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.8;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    h3 {
        margin: 0;
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .spinner {
        text-align: center;
    }
    // .spinner>div {
    //     width: 18px;
    //     height: 18px;
    //     background-color: #f7921e;
    //     border-radius: 100%;
    //     display: inline-block;
    //     -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    //     animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    // }
        // .spinner .bounce1 {
        //     -webkit-animation-delay: -0.32s;
        //     animation-delay: -0.32s;
        // }
    // .spinner .bounce2 {
    //     -webkit-animation-delay: -0.16s;
    //     animation-delay: -0.16s;
    // }
    @-webkit-keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
        }
    }
}

.spinner-wrapper-internal {
    @extend .spinner-wrapper;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 2000;
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}