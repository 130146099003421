.grid-h {
    font-weight: bolder !important;
}

.MuiDataGrid-cellRight {
    padding-top: 16px !important;
}
.inputs-wrap input{
    width: 140px !important;
    margin-right: 10px;
    margin-top: 5px;
  }
  .m-left{
    margin-left: 20px;
  }
