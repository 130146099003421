@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body,
html {
  font-family: "Raleway", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.data-grid {
  width: 100%;
}

.t-primary {
  color: #bfa056;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.save-btn {
  background: #bfa056 !important;
  border-color: #bfa056 !important;
}
.save-btn:hover {
  color: #fff !important;
  background: #bfa056 !important;
  border: #b59852 1px solid !important;
  padding: 0.375rem 0.75rem;
}
.btn-primary:hover {
  background: rgb(63, 71, 148);
  border: #b59852 1px;
  color: #b59852;
}

.btn-primary:focus {
  background: #000;
  border: #b59852 1px;
  color: #b59852;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: #000;
  border: #b59852 1px;
  color: #b59852;
}

.outline-primary {
  color: #bfa056 !important;
}
.navbar-subheading {
  margin-top: 10px;
  background-color: #e5d9bb;
  color: #000;
  padding: 5px;
  border-bottom: 1px solid #827b6a;
}
.navbar-nav li .navbar-nav {
  margin-left: 8px !important;
}
/****** left nav-bar ******/

.left-nav-bx .navbar {
  min-height: 88vh !important;
  background: #bfa056;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0.5rem;
}
.left-nav-bx li {
  width: 100%;
}
.row {
  margin-left: 0px;
}
.call-details {
  width: 600px;
  height: 500px;
  border: 1px solid #b59852;
}
.vl {
  border-left: 1px solid red;
  height: 400px;
}
.left-nav-bx li a {
  color: #fff;
  font-weight: 600;
  padding: 2px 5px !important;
  display: block;
}
.left-nav-bx li.active a,
.left-nav-bx li a:hover {
  color: #000;
  background: #ffffff;
  border-radius: 3px;
}
.heading {
  background-color: #bfa056 !important;
  color: #ffffff !important;
}

.my-owwll-user {
  color: #bfa056 !important;
}

.left-nav-bx li a.active-sublink {
  padding: 2px 5px !important;
}
.modal-header {
  background-color: #bfa056 !important;
}


#overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}
button {
  /* margin: 40px; */
  padding: 5px 20px;
  cursor: pointer;
}
.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid #bfa056;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.active-sublink {
  background: white;
  border-radius: 3px;
  color: black !important;
}

.MuiInputLabel-animated {
  font-size: 16px !important;
  transform: translate(0, 1.8px) scale(0.85) !important;
}
.stnd-btn {
  height: 35px;
}
.sr-input {
  min-width: 220px;
}

.primary {
  background: #bfa056 !important;
  color: #fff !important;
}

.lnk {
  color: #fff;
}

.lnk:hover {
  color: #fff;
  text-decoration: none;
}
.App {
  min-height: calc(100vh - 0px);
}
.config-rows .row {
  margin-bottom: 10px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
  opacity: 1;
}

.sm_wrapper span:not(:empty) {
  display: inline-block;
  margin-right: 5px;
}
.countswrap {
  border-right: 1px solid #fff;
  margin-right: 25px;
  padding-right: 25px;
  float: left;
  /* width: 200px; */
  color: #bfa056;
}
.counts-label-wrap {
  display: flex;
  flex-direction: row;
}
.counts-label-wrap {
  width: 100%;
}
.countswrap span {
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  font-weight: 600;
}
.counts-label {
  padding-right: 5px;
  font-weight: 600;
}
.date-row {
  background: #e9e9e9;
  display: flex;
  min-width: 180px;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  margin-left: 15px;
}
.date-row img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.date-row p {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.office-hours-sec > .row {
  padding-top: 10px;
}
.office-hours-sec {
  border: 1px solid #ccc;
}
.office-hours-title {
  background: #d5d5d5;
  padding: 7px 15px;
  margin-bottom: 10px;
}
.office-hours-title label {
  margin: 0px;
}
.add-category {
  align-items: center;
}
.cross-white {
  font-size: 1.25rem;
  background: #bfa056;
  border-radius: 25px;
  color: white;
  width: 25px;
  height: 25px;
  padding: 3px;
}
.hoverChange :hover {
  padding: 4px;
}
.grey_color {
  color: red;
  font-size: 14px;
}
.option {
  background: #ffffff;
  color: #ff0000;
}
.react-confirm-alert-overlay{
  z-index: 99999 !important;
}
.left-margin{
  margin-left: 120px !important;
}

.left-margin-e{
  margin-left: 130px !important;
}

select option:checked,
select option:focus {
  background: linear-gradient(#ff0000, #ff0000);
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
}
