.signup-up-sec{
    justify-content: space-between;
}

.right-sec{
    float: right;
}
.right-sec input.search-field{
    height: 36px;
    margin: 0 15px 0 2px;
}
.dates-cal{
    position: relative;
    top: -13px;
    float: left;
}
.search-b{
    background: #bfa056 !important;
    color: #fff !important;
    border-radius: 3px !important;
}
.user-table-header{
    display: flex;
    justify-content: space-between;
}
.approve-all:enabled{
    background: #001517 !important;
    color: #fff !important;
    border-radius: 3px !important;
}

.approve-all:disabled{
    background: #98adaf !important;
    color: #fff !important;
    border-radius: 3px !important;
}