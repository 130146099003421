html,
body {
  height: 100vh;
  min-height: 100%;
  /* background: #001517; */
}
.main-bg {
  background: #001517;
  padding-bottom: 50px;
  min-height: 100vh;
  /* height: 100vh; */
}
.login-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.form-login {
  background-color: #ffffff;
  border-radius: 5px;
  border: 2px solid #c0a057;
}

.form-logo {
  background-color: #fafafa;
}

.input-group-text {
  background: #c0a057;
  border: #c0a057 1px;
  color: black;
}
.logo {
  width: 150px;
  height: 165px;
  margin-bottom: 40px;
  background: url(./../../images/owwll-logo.png) no-repeat 0 0;
}
.heading-bx h2 {
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin: 0 0 20px 0;
}
.heading-bx h2 span {
  color: #c0a057;
}
.btn-style {
  min-width: 80px;
  background: #c0a057;
  color: #fff;
}
.login-btn {
  font-weight: 500;
}
.input-group span {
  width: 100%;
  color: red;
  font-size: 14px;
}
