.border-radius {
  border: 1px solid rgb(7, 7, 7);
  border-radius: 10px;
}

.heading {
  background-color: #000;
  color: #b59852;
  padding-left: 8px !important;
  padding: 6px;
}

.btn-outline-secondary {
  color: #bfa056;
  border-color: #001517;
  background-color: #001517;
}
.inputs-wrap input{
  width: 150px !important;
  margin-right: 10px;
  margin-top: 5px;
}
.btn-outline-secondary:hover {
  color: #bfa056;
  border-color: #001517;
  background-color: #001517;
}
.btn-enabled .custom-control.custom-checkbox {
  padding-left: 10px;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}
.search-btn {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  background: #bfa056;
}

.rb {
  border: 1px solid #bfa056;
  border-radius: 5px;
}
.MuiInputLabel-root {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
}
.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-cell-button {
  min-width: 40px;
  display: inline-block;
}
.drop{
  height: 31px;
  margin: 4px 0px 0px;
  padding: 5px;
  border-color: #ced4da !important;
  font-size: .875rem;
  
}
.pearl-black-btn {
  color: #bfa056 !important;
  border-color: #001517 !important;
  background-color: #001517 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.golden-black-btn {
  color: #ffffff !important;
  border-color: #c0a057 !important;
  background-color: #c0a057 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}


.margin10 {
  margin-right: 10px !important;
}
.margin10Left {
  margin-left: 5px !important;
}
.hand:hover {
  cursor: pointer;
  cursor: hand;
}
.pearl-black-btn span {
  font-weight: 400;
  text-transform: capitalize;
}

.golden-black-btn span {
  font-weight: 400;
}

@media (max-width: 1100px) {
  /* body {
    width: 1100px;
  } */
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}
.edit {
  cursor: pointer;
}

.MuiDataGrid-colCellTitle {
  font-weight: 700 !important;
}
.btn-enabled {
  display: flex;
  align-items: center;
}
.brand-title span {
  color: #b59852;
  font-size: 26px;
}

.gridWrapper .MuiDataGrid-cellLeft button {
  background: none !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%);
  color: #bfa056 !important;
}
.gridWrapper .MuiDataGrid-cellLeft button:hover {
  text-decoration: underline;
}
.modal-body-bx > .row {
  /* border-bottom: 1px solid #cccccc2b; */
  padding-top: 6px;
}
.modal-body-bx > .row .lbl {
  margin-bottom: 6px;
}
.modal-body-bx > .row p {
  margin-bottom: 6px;
}
.list-wrapper {
  width: 100%;
  padding: 4px 0 10px;
}
.list-wrapper a {
  display: inline-block;
  cursor: default;
  color: #fff;
  border: 1px solid #b59852;
  margin: 0 4px 4px 0;
  padding: 4px 14px;
  border-radius: 5px;
  font-size: 14px;

  background: #b59852;
}
.list-wrapper a:hover {
  text-decoration: none;
  background: #b59852;
  color: #fff;
  border: 1px solid #b59852;
  /* opacity: 0.9; */
}
.backbutton button {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bfa056;
  font-size: 16px;
  color: #ffffff;
  background: #bfa056;
}

@media (max-width: 1350px) {
  .u-mang-form {
    display: block;
  }
  .btn-enabled {
    width: 100%;
    padding-top: 10px;
  }
  .btn-enabled .ml-4 {
    margin-left: 0 !important;
    padding-left: 10px !important;
  }
}
.tabi-nav {
  display: flex;

  justify-content: left;

  align-items: flex-start;
}

.tabi-nav .modal-title {
  border-right: 1px solid #fff;
  margin-right: 10px;
  padding-right: 10px;
  color: #000;
}
.tabi-nav .modal-title:hover {
  text-decoration: none;
  color: #fff;
}
.tabi-nav .modal-title.active {
  color: #fff;
}

.tabi-nav .modal-title:last-child {
  border-right: 0;
}

.modal-title2 {
  color: #fff;
}

.table-o {
  padding: 25px;
}

.user-profile {
  color: white;
}
.overlay-custom-class-name{
  z-index: 5000;
}
