.footer {
  position: fixed; 
  height: 40px;
  bottom: 0px; 
  left: 0px;
  right: 0px;
  
  margin-bottom: 0px;
  margin-top: -40px;
  padding: 0 !important;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
}
