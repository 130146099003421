.view-r-btn {
  margin-top: 12px;
  margin-left: 20px;
}
.report-view-bx {
  min-height: 200px;
  border: 1px solid #ccc;
  margin-bottom: 40px;
}
.label-bx h5 {
  font-size: 16px;
  font-weight: 600;
}
.error-msg {
  display: block;
  width: 100%;
  padding-left: 6px;
  padding-top: 5px;
}
.report-view-bx,
.report-view-bx * {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
.formouter label,
.formouter .form-control,
.formouter button {
  float: left;
  margin-top: 0;
}
.formouter button {
  margin-left: 10px;
}
.formouter .form-control {
  width: 200px;
}
.export-btn {
  text-transform: capitalize !important;
}
.box-wrapper{
  display: flex;
  justify-content:space-between;
}
.box1{
  padding: 15px;
  width: 31%;
  text-align: center;
  color: white;
  border-radius: 10px;
  /* margin:0 2%; */
}
.box1 h6{
  padding-bottom: 10px;
  font-size: 22px;
}

.money{
  font-weight:"bold";
   font-size:22px;
   font-family:  "Gill Sans", sans-serif;
}

.text1{
  width: 30%;
  text-align: center;
  font-weight: 500;
}

.box2{
  padding: 10px;
  width: 45%;
  text-align: center;
  color: black;
  border-radius: 10px;

}
.text2{
  width:100%;
  text-align: center;
  font-weight: 500;
  /* margin-left: 210px; */
}
.donut{
  margin-top: -100px;
  margin-left: 50px;
}
select,option{
  width:220px;
  padding: 8px;
  border-radius: 4px;
  border-color: grey !important;
  border-width: 1px;
  margin: 10px;
}
.dropdown {
  display: flex;
  font-family:  "Gill Sans", sans-serif; 
}
.apply{
  color: white;
  font-weight: normal;
  background-color: #c0a057;
  border: #c0a057;
  border-radius: 5px;
  width: 120px;
  padding: 5px;
  height: 40px;
  margin-top: 10px;
  
}
.vl {
  border-left: 0.5px solid grey;
  height: 500px;
}
.set_table{
  margin-bottom: 50px;
}

th{
  color: white;
  background-color: #c0a057;
  
}
table, th, td {
  border:1px solid lightgrey;
  border-collapse: collapse;
  padding: 8px;
}
.set_table{
  margin-top: 5%;
}

.portion {
  /* background-color: rgb(245, 245, 245); */
  border-radius: 10px;
  width: 48%;
  /* margin-left: 10px; */
  padding: 10px;

}
.portion2{
  margin-left: 20px;
  overflow: hidden;
}
.portion>h3{
  margin-bottom: 0;
}
.portion hr{
  border-top: 1px solid rgb(0, 0, 0, 0.73);
  margin-bottom: 25px;
  margin-top: 5px;
}
.box1 hr{
  border-top: 1px solid #fff;
  margin-bottom: 10px;
}
.box1 p.money{
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
}
.box2wrap{
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
}
.box2 p.money{
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom:0px;
  font-weight: 600;
  font-size: 18px;
}

.set_table table tr td, .set_table table tr td:first-child, .set_table table tr td:last-child{
  font-family: Arial, Helvetica, sans-serif;
}
.set_table table tr th{
  font-weight:500
}

.left-nav-bx{
  height: 100%;
}
.set_table h3{
  font-size: 24px;
}