.administartor {
  background: #b59852;
}

.table-width {
  width: 40%;
}

.form-width {
  width: 40%;
}

.asterisk-bx {
  font-size: 20px;
  display: inline;
  font-weight: 600;
}

.inside-text {
  color: rgb(148, 148, 148);
  position: relative;
  margin-left: 1%;
  top: -30px;
}
.i-fields-wrap{
  width: 400px;
}
.i-fields-wrap input{
  margin-bottom: 13px !important;
}
.inside-text2 {
  color: rgb(148, 148, 148);
  position: absolute;
  bottom: -11px;
  right: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;

}
.label-wrap{
  width: 120px;
}
.label-wrap label{
  min-height: 36px;
  line-height: 36px;
}
.input-wrapper{
  position: relative;
}
.i-fields-wrap select{
  margin-top: 5px !important;
}