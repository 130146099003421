
.header-bx {
  background: #001517;
 
}

.navbar-text {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}
.logout-btn {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  background: #bfa056;
}
.header-bx .navbar-nav .nav-item {
  margin-right: 5px;
}
.header-bx .navbar-nav .nav-item a {
  color: #fff;
  padding: 5px 15px;
}
.header-bx .navbar-nav .nav-item a:hover,
.header-bx .navbar-nav .nav-item a:focus {
  color: #fff;
}
.header-bx .navbar-nav .nav-item.active a,
.header-bx .navbar-nav .nav-item a:hover {
  background: #bfa056;
  color: #fff;
  border-radius: 3px;
}

.owwll-logo-bx {
  margin-right: 40px;
  width: 60px;
  height: 60px;
  background: url(./../../images/owwll-main-logo.png) no-repeat 0 0;
  background-size: 100%;
}
.refresh-icon{
  margin-right: 10px;
  min-width: 42px !important;
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url(./../../images/refresh-icon.png) no-repeat 0 0;
}
